import React from "react";

const Testimonial = () => {
  return (
    <>
      <div className="md:m-28 md:mb-10">
        <div className="flex flex-col md:flex-row pt-10 md:pt-0 bg-black">
          <div className="flex flex-col justify-center items-start md:w-1/3 bg-greyMedium"></div>
          <div className="flex flex-col justify-center items-start md:w-40md:w-60 ">
            <p className="text-red-400 text-sm text-center md:mx-20 pt-10">
              Author Name
            </p>
            <h1 className="text-red-600 text-5xl font-bold text-center md:mx-20 pt-5 pb-16">
              The best way to <br /> secure a website
            </h1>
            <p className="text-white text-mds font-bold text-center md:mx-20 py-10">
              Read More
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-10 md:flex-row pt-10 md:pt-0 mt-6">
          <div className="flex flex-col justify-center items-start md:w-1/2  bg-red-600 px-20 py-10">
            <h3 className="text-white text-2xl font-semibold">
              keys to protect apps that actually scale and sells users{" "}
            </h3>
            <p className="text-white text-md py-5 opacity-75">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. At
              soluta delectus voluptatem repellendus ex officia quo dolorem sunt
              odio cum esse dolore modi ullam amet, rem nemo laboriosam velit
            </p>
            <p className="text-white text-md pt-8">Read More</p>
          </div>
          <div className="flex flex-col justify-center items-start md:w-1/2  bg-red-600 px-20 py-10">
            <h3 className="text-white text-2xl font-semibold">
              keys to protect apps that actually scale and sells users{" "}
            </h3>
            <p className="text-white text-md py-5 opacity-75">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. At
              soluta delectus voluptatem repellendus ex officia quo dolorem sunt
              odio cum esse dolore modi ullam amet, rem nemo laboriosam velit
            </p>
            <p className="text-white text-md pt-8">Read More</p>
          </div>
        </div>
        <h3 className="text-red-600 text-2xl text-center font-bold pt-20 pb-8">
          “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt.”
        </h3>
        <div className="flex justify-center items-center pb-5">
          <div className="flex justify-center items-center bg-black rounded-full h-8 w-8"></div>
        </div>
        <p className="text-gray-500 text-center">- John Doe, CEO of Company</p>
      </div>
      <div className="bg-gray-200 mb-10">
        <h4 className="text-red-600 text-2xl text-center font-bold pt-20 pb-8">
          Ready to get started?
        </h4>
        <p className="text-gray-900 text-center text-lg">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit.
          <br />
          apiente ipsum quisquam expedita nihil recusandae voluptatibus
          <br />
          Recusandae consectetur reprehit sapiente nostrum architecto.
        </p>
        <div className="flex justify-center items-center pb-10">
          <button className="bg-black text-white px-10 py-3 rounded-sm mt-10 ">
            Start Free Trial
          </button>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
