import React from "react";

const Footer = () => {
  return (
    <div>
      <div className="flex flex-col md:flex-row justify-center pt-10">
        <div className="flex flex-col justify-center md:ml-20 md:w-1/3">
          <h5 className="text-black text-2xl font-bold"> Falcon </h5>
        </div>
        <div className="flex flex-col justify-center  md:w-1/3">
          <p className="text-red-600 text-md py-2 opacity-75">
            Latest Blog Post
          </p>
          <h5 className="text-red-500 text-2xl font-bold">
            Ready to get started?
          </h5>
          <p className="text-gray-800 text-md py-5">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. At soluta
            delectus voluptatem repellendus ex officia quo dolorem sunt odio cum
            esse dolore modi ullam amet, rem nemo laboriosam velit lorem ipsum
            dolor sit amet consectetur adipisicing elit. At soluta delectus
            voluptatem repellendus ex officia quo dolorem sunt
          </p>
        </div>

        <div className="flex flex-col justify-center md:w-1/3">
          <div className="flex flex-row justify-center items-center ">
            <ul className="flex flex-col justify-center items-center md:w-1/3">
              <li className="text-gray-800 text-md py-2 font-semibold">
                Product
              </li>
              <li className="text-gray-800 text-md py-2 opacity-75">Product</li>
              <li className="text-gray-800 text-md py-2 opacity-75">Pricing</li>
              <li className="text-gray-800 text-md py-2 opacity-75"> About </li>
              <li className="text-gray-800 text-md py-2 opacity-75">Contact</li>
            </ul>
            <ul className="flex flex-col justify-center items-center md:w-1/3">
              <li className="text-gray-800 text-md py-2 font-semibold">
                Company
              </li>
              <li className="text-gray-800 text-md py-2 opacity-75">Company</li>
              <li className="text-gray-800 text-md py-2 opacity-75"> Terms </li>
              <li className="text-gray-800 text-md py-2 opacity-75"> Terms </li>
              <li className="text-gray-800 text-md py-2 opacity-75">Privacy</li>
            </ul>
          </div>
          <div className="flex justify-center items-center pb-5 pt-10 ">
            @2022 - 2023 Privacy - Terms
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
