import React from "react";

const HeroSection = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row pt-10 md:pt-0">
        <div className="flex flex-col justify-center items-start md:w-1/2">
          <h1 className="text-5xl mx-8 font-bold text-textRed md:px-20 pb-14 md:pt-16 ">
            Your Best Value Preposition
          </h1>
          <p className="text-gray-900 mx-8 md:text-left md:px-20">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptas, quod, quia, voluptates quae voluptatibus quibusdam
            voluptatum quidem quos nemo quas. Quisquam, quae. Quisquam, quae.
            Quisquam, quae. Quisquam, quae.
          </p>
          <button
            className="bg-black md:mx-28 mx-8 text-white px-10 py-3 rounded-sm mt-10 "
            style={{
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            Start Free Trial
          </button>
        </div>
        <div className="flex justify-center items-center md:w-1/2 md:mt-0 mt-10">
          <div className="md:mt-16 border border-[#f3525a]">
            <span className="flex flex-row p-[1rem] gap-x-[1rem]">
              <div className="border border-[#f3525a] h-[1rem] w-[1rem] rounded-lg"></div>
              <div className="border border-[#f3525a] h-[1rem] w-[1rem] rounded-lg"></div>
              <div className="border border-[#f3525a] h-[1rem] w-[1rem] rounded-lg"></div>
            </span>
            <img
              src="asset/banner.svg"
              alt="hero"
              className="md:py-10 md:px-20"
            />
          </div>
        </div>
      </div>
      <h5 className="text-2xl font-bold text-center mt-20">
        Trusted by the world&apos;s best Companies
      </h5>
      <div className="flex flex-col md:flex-row justify-center items-center md:space-x-20 space-y-10 md:space-y-0 mt-10">
        <img
          src="asset/logo/cocacola.svg"
          alt="cocacola"
          className="w-40 h-20"
        />
        <img src="asset/logo/google.svg" alt="google" className="w-40 h-20" />
        <img
          src="asset/logo/microsoft.svg"
          alt="microsoft"
          className="w-40 h-20"
        />
        <img src="asset/logo/stripe.svg" alt="nvidia" className="w-40 h-20" />
        <img src="asset/logo/mercedes.svg" alt="tesla" className="w-40 h-20" />
      </div>
    </>
  );
};

export default HeroSection;
