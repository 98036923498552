import React from "react";

const About = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row pt-10 md:pt-0 mt-20 bg-greyLight">
        <div className="flex flex-col justify-center items-start md:w-1/2">
          <h5 className="text-2xl  mx-8 font-bold text-textRed md:px-20">
            Your Best Value Proposition
          </h5>
          <p className="text-gray-100 mx-8 md:text-left md:px-20 py-8 ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Velit impedit
            distinctio magnam fugiat ea inventore porro.
          </p>
          <p className="text-red-300 mx-8 md:text-left md:px-20 pb-4 ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          {/* circle white */}
          <div className="flex justify-center items-center md:px-20 mx-8 py-4">
            <div className="flex justify-center items-center bg-white rounded-full h-8 w-8"></div>
            <p className="text-gray-100 mx-3">
              {" "}
              Srijan Gupta, Product Designer{" "}
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center md:w-1/2 md:mt-0 mt-10">
          <img src="asset/Media.png" alt="hero" className="md:py-20 md:px-20" />
        </div>
      </div>
      <div className="flex flex-col md:flex-row pt-10 md:pt-0 bg-gray-200">
        <div className="flex justify-center items-center md:w-1/2 md:mt-0 mt-10">
          <img src="asset/About.png" alt="hero" className="md:py-20 md:px-20" />
        </div>
        <div className="flex flex-col justify-center items-start md:w-1/2">
          <h5 className="text-2xl  mx-8 font-bold text-textRed md:px-20">
            Your Best Value Proposition
          </h5>
          <p className="text-black mx-8 md:text-left md:px-20 py-8 ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Velit impedit
            distinctio magnam fugiat ea inventore porro.
          </p>
          <p className="text-red-500 mx-8 md:text-left md:px-20 pb-4 ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          {/* circle white */}
          <div className="flex justify-center items-center md:px-20 mx-8 py-4">
            <div className="flex justify-center items-center bg-greyLight rounded-full h-8 w-8"></div>
            <p className="text-black mx-3"> Srijan Gupta, Product Designer </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row pt-10 md:pt-0 ">
        <div className="flex flex-col justify-center items-start md:w-1/2">
          <h5 className="text-2xl  mx-8 font-bold text-textRed md:px-20">
            Your Best Value Proposition
          </h5>
          <p className="text-black mx-8 md:text-left md:px-20 py-8 ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Velit impedit
            distinctio magnam fugiat ea inventore porro.
          </p>
          <p className="text-red-500 mx-8 md:text-left md:px-20 pb-4 ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          {/* circle white */}
          <div className="flex justify-center items-center md:px-20 mx-8 py-4">
            <div className="flex justify-center items-center bg-greyLight rounded-full h-8 w-8"></div>
            <p className="text-black mx-3"> Srijan Gupta, Product Designer </p>
          </div>
        </div>
        <div className="flex justify-center items-center md:w-1/2 md:mt-0 mt-10">
          <img src="asset/Media.png" alt="hero" className="md:py-20 md:px-20" />
        </div>
      </div>
    </>
  );
};

export default About;
